@import url("https://fonts.googleapis.com/css2?family=Oswald&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,800&display=swap");
:root {
  /* dark shades of primary color*/
  /* --clr-primary-1: hsl(43, 86%, 17%); */
  --clr-primary-5: #2caeba;
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --ff-primary: "Raleway", sans-serif;
  --ff-secondary: "Oswald", sans-serif;
  --transition: all 0.3s linear;
  --spacing: 0.2rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 900px;
  --fixed-width: 700px;
}

/*/////////////////////////////////////////////
///////////////// Universal styles /////////////
///////////////////////////////////////////////*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}
img {
  display: block;
}
.App {
  max-width: var(--max-width);
  margin: 2% auto;
  padding: 2%;
}
main {
  padding: 2% 0;
}

/*/////////////////////////////////
////////////Header////////////////
//////////////////////////////////*/

header {
  display: flex;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  width: 100%;
  font-family: var(--ff-primary);
  border-bottom: 1px solid #ddd;
  padding-bottom: 2%;
  margin: 0 auto 5% auto;
}

.logo img {
  display: block;
  width: 60px;
  -webkit-transition: background-color 250ms ease-in-out,
    -webkit-transform 150ms ease;
  transition: background-color 250ms ease-in-out, -webkit-transform 150ms ease;
  transition: background-color 250ms ease-in-out, transform 150ms ease;
  transition: background-color 250ms ease-in-out, transform 150ms ease,
    -webkit-transform 150ms ease;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.logo img:hover {
  box-shadow: 0px 2px var(--clr-primary-5);
  border-radius: 50%;
  transform: translateY(-18px);
}
header h1 {
  -webkit-transition: background-color 250ms ease-in-out,
    -webkit-transform 150ms ease;
  transition: background-color 250ms ease-in-out, -webkit-transform 150ms ease;
  transition: background-color 250ms ease-in-out, transform 150ms ease;
  transition: background-color 250ms ease-in-out, transform 150ms ease,
    -webkit-transform 150ms ease;
  padding: 0.6em 0.2em;
  font-family: "Oswald", sans-serif;
  letter-spacing: 4px;
  color: #222;
  border-radius: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
header h1:hover {
  box-shadow: 0px 2px var(--clr-primary-5);
}
/*///////////////////////////////
////////////Navigation///////////
////////////////////////////////*/
.nav {
  display: none;
}

/*/////Moble menu toggle button/////////*/
.toggle-btn {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: #777;
  cursor: pointer;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.toggle-btn:hover {
  color: var(--clr-primary-5);
  background: transparent;
}

@media screen and (min-width: 768px) {
  .toggle-btn {
    display: none;
  }
  .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .nav a {
    display: inline-block;
    font-size: 1.1em;
    color: rgb(109, 105, 105);
    background-color: transparent;
    padding: 0.6em 0.9em;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .nav a:hover {
    color: var(--clr-primary-5);
    box-shadow: 0px 2px var(--clr-primary-5);
  }

  .nav a:active,
  .nav a:focus {
    color: #111111;
  }
}

@media screen and (min-width: 992px) {
  .navbar {
    background: transparent;
  }
}

.sidebar {
  background: #fcfcfc;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: -ms-grid;
  display: grid;
  place-items: center;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.show-sidebar {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.sidebar-links li {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.sidebar-links li a {
  display: block;
  color: rgb(109, 105, 105);
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  -webkit-transition: 0.3s ease-in-out;
  transition: var(--transition);
  font-family: "Raleway", sans-serif;
}
.sidebar-links li a:hover {
  color: var(--clr-primary-5);
}
.close-btn {
  position: absolute;
  right: 4.75%;
  top: 2.75%;
  font-size: 2.5rem;
  background: transparent;
  border-color: transparent;
  color: #777;
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .sidebar {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
/*////////////////////////////////
////////////Hero section//////////
/////////////////////////////////*/

.hero {
  width: 100%;
  margin: 0 auto;
  height: 80vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Raleway", sans-serif;
}

.hero-info {
  padding: 2% 2%;
  width: 100%;
  color: #faf7f8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-grid-column-align: space-around;
  justify-items: space-around;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  height: 40%;
}

.hero-info h2 {
  letter-spacing: 8px;
  padding-bottom: 0.6em;
  font-size: 3em;
  color: var(--clr-primary-5);
  text-shadow: 2px 1px #1a6b63;
}

.hero-info p {
  letter-spacing: 4px;
  font-weight: 800;
  padding-bottom: 2.2em;
  font-size: 1.6em;
  color: #333333;
}

/*////////////////////////////////////
/////////////About me/////////////////
/////////////////////////////////////*/

.about-me {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 2% 2% 10% 2%;
  width: 100%;
}
.me-info {
  width: 100%;
}
.me-info h3 {
  font-size: 1.8em;
  padding: 0 2% 4% 0;
  font-family: "Oswald", sans-serif;
  letter-spacing: 2px;
  color: #333;
}

.me-info p {
  width: 80%;
  line-height: 1.6em;
  font-size: 1.3em;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #777;
}
.shot img {
  width: 90%;
  display: block;
  -o-object-fit: fill;
  object-fit: fill;
  border: 1.2em solid #faf7f8;
  margin-top: 10%;
}


/*////////////////////////////////
//////////////Footer//////////////
//////////////////////////////////*/
footer {
  border-top: 1px solid #ddd;
  padding-bottom: 1em;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  font-family: "Raleway", sans-serif;
  margin: 5% auto 0 auto;
  width: 100%;
}

footer p {
  line-height: 1.6em;
  font-weight: 400;
  color: #777;
  margin: 0 auto;
  padding: 2%;
  width: 100%;
}

/*////////////////////////
///////////Buttons//////////
////////////////////////////*/

button:focus {
  background: #1d223c;
  color: #f3edf1;
}

/*/////////////////////////////////////////
/////////////Social media icons//////////
//////////////////////////////////////////*/

a.social-media {
  display: inline-block;
  color: #777777;
  padding: 1%;
  font-size: 25px;
}

a.social-media
{
  color: #000000;
  transition: var(--transition);
}

a.social-media:hover {
  color: var(--clr-primary-5);
}

/*//////////////////////////////////////////////////
//////////////Contact me section////////////////////
/////////////////////////////////////////////////////*/

.form-container {
  display: flex;
}

.form-container h3 {
  font-family: "Oswald", sans-serif;
  padding-bottom: 2%;
  font-size: 2.1em;
}

.form-container p {
  margin-top: 10%;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 1.3em;
}

.form-container a {
  margin-top: 5%;
  display: inline-block;
  color: #000000;
  transition: var(--transition);
}

.form-container a:hover {
  color: var(--clr-primary-5);
  box-shadow: 0px 2px var(--clr-primary-5);
}

form {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 40vw;
  padding: 0.8rem 1.1rem;
}

.form-control{
  display: block;
  width: 80%;
  padding: 12px 16px;
  padding: 0.75rem 1rem;
  border: 1px solid #e1e1e1;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  background: #fafafa;
  letter-spacing: var(--spacing);
}
.form-control::placeholder {
  font-family: var(--ff-primary);
  background-color: #fafafa;
  letter-spacing: var(--spacing);
}
.form-container h4 {
  padding-bottom: 5%;
  font-family: "Oswald", sans-serif;
  font-size: 1.7em;
  font-weight: 300;
}

.form-container button {
  display: block;
  width: 40%;
  padding: 0.5rem;
  background-color: #000000;
  color: white;
  font-size: 1.2em;
  font-family: "Oswald", sans-serif;
  cursor: pointer;
  border: none;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  margin-top: 4%;
}

.form-container button:hover {
  color: white;
  background-color: var(--clr-primary-5);
}


/*///////////////////////////////////////////
///////////////Media queries///////////////
////////////////////////////////////////////*/
/* 
@media only screen and (max-device-width: 768px) {
  .me-info p {
    width: 80%;
  }
  form {
    width: 60%;
  }

  .hero-info {
    padding: 5% 5%;
  }
  .form-container {
    flex-direction: column;
  }
} */

@media only screen and (max-device-width: 768px) {
  .hero-info {
    padding: 5% 5%;
  }

  .hero-info h1 {
    font-size: 1.9em;
  }
  .about-me {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .shot img {
    width: 70%;
  }
  .me-info p {
    width: 100%;
    font-size: 1em;
  }
  
  span.hover {
    display: none;
  }
  .form-container {
    flex-direction: column;
  }
  form {
    width: 80%;
  }

  .form-container h4 {
    font-size: 1.2em;
  }
  
  .toggle-btn {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
}
/* section */
.section {
  padding: 5rem 0;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
.section-title {
  font-size: 1.8em;
  padding: 0 2% 4% 0;
  font-family: "Oswald", sans-serif;
  letter-spacing: 2px;
  color: #333;
}
.bg-grey {
  background: var(--clr-grey-10);
}
/*Project*/

.projects {
  background: var(--clr-grey-10);
}
.project {
  display: grid;
  margin-bottom: 4rem;
}
.project-img {
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  height: 19rem;
  z-index: 1;
}
.project-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.project-info {
  background: var(--clr-white);
  padding: 1rem 2rem;
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}

.project-number {
  display: inline-block;
  font-size: 1.25rem;
  color: var(--clr-primary-5);
  margin-bottom: 0.75rem;
}
.project-info h3 {
  font-weight: 500;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-family: "Oswald", sans-serif;
}
.project-desc {
  word-spacing: 15px;
  color: #777;;
  font-family: "Raleway", sans-serif;
}
.project-stack {
  margin-bottom: 1rem;
}
.project-stack span,
.about-stack span {
  display: inline-block;
  background: var(--clr-grey-9);
  color: var(--clr-grey-5);
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: var(--radius);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.85rem;

}
.about-stack span {
  margin-top: 0.5rem;
}
.project-icon {
  color: var(--clr-primary-5);
  font-size: 1.25rem;
  margin-right: 0.5rem;
  transition: var(--transition);
}
.project-icon:hover {
  color: #000000;;
}
@media screen and (min-width: 576px) {
  .project-img {
    height: 19rem;
  }
}
@media screen and (min-width: 768px) {
  .project-img {
    height: 22rem;
  }
}
@media screen and (min-width: 992px) {
  .project {
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
  }
  .project-img {
    grid-column: 1 / span 8;
    grid-column-end: 8;
    grid-row: 1 / 1;
    height: 30rem;
    border-radius: var(--radius);
    box-shadow: var(--dark-shadow);
  }
  .project-info {
    border-radius: var(--radius);
    box-shadow: var(--dark-shadow);

    z-index: 1;
    grid-column: 5 /12;
    grid-row: 1 / 1;
  }
  .project:nth-of-type(even) .project-img {
    grid-column: 5 / -1;
    grid-row: 1 / 1;
  }
  .project:nth-of-type(even) .project-info {
    grid-column: 2 / span 7;
    grid-row: 1 / 1;
    text-align: left;
  }
}

